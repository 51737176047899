@import '../../assets/scss/variables';

// **** Terms and Conditions Page CSS **** //
.tc-block{
    .terms-and-conditions-section{
        padding: 2rem 2rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        p{
            font-size: 16px;
            line-height: 30px;
            margin: 10px 0px 10px;
            font-family: $font-secondary;
            a{
                color: $primary;
                text-decoration: underline;
            }
        }
        ol{
            margin: 0px;
            padding: 5px 0px;
            counter-reset: item;
            &.sub-number-ol{
                counter-reset: item;
                list-style-type: none;
                margin: 0px;
                padding: 5px 0px;
                li{
                    counter-increment: item;
                    margin-bottom: 25px;
                    font-family: $font-secondary;
                    .heading{
                        font-size: 18px;
                        font-weight: 600;
                        color: $primary;
                        font-family: $font-base;
                        margin-bottom: 1rem;
                    }
                    &::before{
                        content: counters(item,".") " ";
                        font-weight: 600;
                        color: $white;
                        font-size: 18px;
                        background: $secondary;
                        border-radius: 30px;
                        width: 32px;
                        height: 32px;
                        display: inline-block;
                        text-align: center;
                        line-height: 32px;
                        margin-right: 8px;
                    }
                    ol{
                        counter-reset: item;
                        padding: 5px 25px;
                        margin-top: 8px;
                        li{
                            counter-increment: item;
                            list-style-type: none;
                            line-height: 28px;
                            font-size: 15px;
                            font-weight: 400;
                            margin-bottom: 10px;
                            padding-left: 25px;
                            position: relative;
                            &::before{
                                content: counters(item,".") " ";
                                position: absolute;
                                left: -10px;
                                font-size: 15px;
                                background: transparent;
                                width: auto;
                                height: auto;
                                color: $secondary;
                                line-height: 28px;
                                margin-right: 0px;
                            }
                            ol{
                                li{
                                    padding-left: 30px;
                                    &::before{
                                        left: -20px;
                                    }
                                }
                            }
                        }
                    }
                    a{
                        color: $primary;
                        text-decoration: underline;
                    }
                }
            }
            ul{
                li{
                    &::before{
                        display: none !important;
                    }
                }
            }
        }
    }
    &::after{
        content: '';
        bottom: 10%;
        background-size: 65%;
    }
    &::before{
        background-size: 65%;
    }
}
.terms-conditions{
    min-height: calc(100vh - 417px);
    p{
        line-height: 24px;
    }
}
.minHeight{
    min-height: calc(100vh - 417px) !important;
    height: calc(100vh - 417px);
    display: flex;
    align-items: center;
    justify-content: center;
    .loading-block{
        text-align: center;
    }
}

@media (max-width:960px){
    .tc-block{
        .terms-and-conditions-section{
            padding: 1rem 20px;
            .table-responsive{
                width: 100%;
                overflow: scroll;
                .content-table{
                    width: 900px;
                }
            }
            ol.sub-number-ol li a{
                word-break: break-all;
            }
        }
    }
}