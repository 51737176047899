@import '../../assets/scss/variables';

.faq-tabs{
    padding: 0rem 0rem;
    background: $white;
    position: relative;
    .p-tabview{
        .p-tabview-nav-container{
            .p-tabview-nav-content{
                background: $quinary;
                border-radius: 50px;
                .p-tabview-nav{
                    display: inline-flex;
                    border-radius: 50px;
                    width: 100%;
                    background: $quinary;
                    border: none;
                    margin: 0 auto;
                    min-height: 62px;
                    .p-unselectable-text{
                        .p-tabview-nav-link{
                            padding: 1.5rem 1rem;
                            background: $quinary;
                            color: $black;
                            border-radius: 50px;
                            border: none;
                            min-width: 125px;
                            justify-content: center;
                            margin: 0px;
                            .p-tabview-title{
                                font-weight: 600;
                                font-size: 16px;
                                font-family: $font-base;
                            }
                            &:focus{
                                box-shadow: none;
                            }
                            &:focus{
                                box-shadow: none;
                            }
                        }
                        &.p-highlight{
                            .p-tabview-nav-link{
                                background: $primary;
                                color: $white;
                            }
                        }
                    }
                    .p-tabview-ink-bar{
                        display: none;
                    }
                }
            }
        }
        .p-tabview-panels{
            background: transparent;
            padding: 2rem 0px;
            h2{
                text-align: left;
                margin-bottom: 2rem;
                font-size: 28px;
            }
            .p-accordion{
                padding: 0px;
                margin: 0px;
            }
            .p-accordion-content{
                img{
                    max-width: 100%;
                }
                a{
                    img{
                        width: auto;
                    }
                }
            }
        }
    }
    .search-block{
        position: absolute;
        right: 10px;
        z-index: 1;
        top: 9px;
        .pi-search{
            margin-left: 10px;
        }
        .p-inputtext{
            border-radius: 25px;
            width: 100%;
            height: 44px;
        }
        .search-button{
            position: absolute;
            right: 4px;
            border-radius: 30px;
            padding: 11px 30px;
            top: 3px;
            background: $secondary;
            border: none;
            .p-button-label{
                font-size: 14px;
                font-weight: 400;
            }
            &:hover{
                background: $primary;
            }
        }
        &.regular-layout{
            right: 5rem;
            top: -60px;
        }
    }
    .loading-container{
        .loading-block{
            text-align: center;
            min-height: 260px;
            p{
                margin: 0px;
                font-size: 16px;
            }
        }
    }
}
.form {
    .error-message{
        color: $secondary;
        font-size: 13px;
        margin: 5px 2px;
    }
}
.faq-main .custom-container{
    padding: 4rem 0rem 1rem !important;
}

@media (max-width:960px){
    .faq-tabs{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: block;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 1.5rem 1rem !important;
                                &::after{
                                    display: none;
                                }
                                .p-tabview-title{
                                    font-size: 16px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .search-block{
            position: unset;
            text-align: right;
            margin-bottom: 1rem;
        }
    }
    .faq-main .custom-container{
        padding: 4rem 1.5rem 1rem !important;
    }
}
@media (min-width:768px) and (max-width:960px){
    .faq-tabs{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: inline-flex;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 1.5rem 2rem;
                            }
                        }
                    }
                }
            }
        }
        .search-block{
            position: unset;
            text-align: right;
            margin-bottom: 1rem;
        }
        &.device-tabs{
            .p-tabview{
                .p-tabview-nav-container{
                    .p-tabview-nav-content{
                        .p-tabview-nav{
                            display: block;
                            .p-unselectable-text{
                                .p-tabview-nav-link{
                                    padding: 1rem 2rem;
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .faq-tabs{
        &.device-tabs{
            .p-tabview{
                .p-tabview-nav-container{
                    .p-tabview-nav-content{
                        .p-tabview-nav{
                            display: inline-flex;
                            .p-unselectable-text{
                                .p-tabview-nav-link{
                                    padding: 1.4rem 1.2rem;
                                    .p-tabview-title{
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
        .search-block{
            position: unset;
            text-align: right;
            margin-bottom: 1rem;
        }
    }
    .faq-main .custom-container{
        padding: 4rem 1.5rem 1rem !important;
    }
}
@media (min-width:1200px) and (max-width:1340px){
    .faq-tabs{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 1.5rem 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:768px){
    .faq-tabs{ 
        .p-tabview-nav-content{
            background: none !important;
            border-radius: 0px !important;
        }
    }
}

@-moz-document url-prefix() {
    .faq-tabs .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav .p-unselectable-text .p-tabview-nav-link .p-tabview-title{
        font-weight: 500;
        font-size: 15px;
  }
}
