@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Fonts **** //
@import url('https://fonts.bunny.net/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: $quaternary;
    }
    &::-webkit-scrollbar-thumb {
        background: $teritary;
        padding: 2px;
    }
}
.err-msg{
    color:red
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.p-inputtext{
    border: 1px solid $input-border !important;
    background-color: $input-field;
}
.p-dropdown .p-dropdown-label{
    border: none !important;
}

.p-component{
    font-family: $font-secondary;
}

@mixin transition { 
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

// **** Header Menu CSS **** //
.header-navigation{
    .p-menubar{
        background: transparent;
        border: none;
        padding: 0.5rem 0;
        .p-menubar-root-list{
            margin-left: auto;
            .p-menuitem{
                margin: 0px 5px;
                &.menu-item-pricing {
                    margin-left: 90px;
                    .p-menuitem-link {
                        .p-menuitem-text {
                            font-size: 1.4em; 
                        }
                    }
                }

                .p-menuitem-content{
                    background: transparent;
                    border-radius: 30px;
                    &:hover{
                        background: transparent;
                        border-radius: 30px;
                    }
                }
                .p-menuitem-link{
                    .p-menuitem-text{
                        font-size: 18px;
                        color: $white;
                        font-family: $font-base;
                                            }
                    &:hover{
                        background: $mainmenuover;
                        border-radius: 30px;
                        .p-menuitem-text,.p-menuitem-icon{
                            color: $white;
                        }
                    }
                    &:focus{
                        box-shadow: none;
                    }
                }
                &.p-menuitem-active{
                    .p-menuitem-link{
                        background: $mainmenu;
                        border-radius: 30px;
                        .p-menuitem-text{
                            color: $white;
                        }
                        &:focus{
                            box-shadow: none;
                        }
                        &:hover{
                            background: $btnprimary;
                            .p-menuitem-text{
                                color: $white;
                            }
                        }
                    }
                }
                &.active{
                    .p-menuitem-link{
                        background: $secondary;
                        border-radius: 30px;
                        .p-menuitem-text{
                            color: $white;
                        }
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }
            .p-button{
                padding: 0px;
                background: none;
                border: none;
                .p-menuitem-link{
                    padding: 20px 50px;
                    border-radius: 50px;
                    background: rgba(255, 255, 255, 0.20);
                    backdrop-filter: blur(15px);
                    margin: 0px 0px;
                    border: 1px solid #b8d1db;
                    .p-menuitem-text{
                        font-family: $font-base;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    &:hover{
                        background: $secondary;
                    }
                }
                &.login-btn{
                    margin-left: 2rem;
                }
            }
        }
        .p-menubar-end{
            .user-button{
                min-height: 55px;
                background: $teritary !important;
                width: 55px;
                padding: 0px !important;
                border: none;
                &:hover{
                    background: $secondary !important;
                }
            }
            .p-dropdown{
                padding: 5px 20px 5px 15px;
                border-radius: 50px;
                background: $btnsecondary;
                backdrop-filter: blur(15px);
                margin: 0px 8px;
                border: none;
                .p-dropdown-label{
                    font-family: $font-base;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    border: none !important;
                                    }
                .p-dropdown-trigger{
                    padding: 0px;
                    width: 1rem;
                    .p-dropdown-trigger-icon{
                        color: $white;
                    }
                }
            }
            .p-button{
                padding: 17px 25px;
                border-radius: 50px;
                background: $btnsecondary;
                backdrop-filter: blur(15px);
                margin: 0px 8px;
                border: none;
                .p-button-label{
                    font-family: $font-base;
                    font-size: 16px;
                    font-weight: 600;
                }
                &:hover{
                    background: $btnsecondaryover;
                }
                &.pricing-btn{
                    background: $btnprimary !important;
                    position: relative;
                    right: 18px;
                    padding: 15px 20px;
                    .p-button-label{
                        font-size: 18px;
                        font-weight: 500;
                    }
                    &:hover{
                        background: $btnprimaryover !important;
                    }
                }
            }
            b{
                color: $white;
                font-family: $font-secondary;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 180px;
                white-space: nowrap;
                text-align: left;
                margin-left: 5px;
            }
        }
    }
}

.lang-tl{
    .p-menuitem-text{
        font-size: 16px !important;
    }
    .p-dropdown .p-dropdown-label{
        font-size: 15px !important;
    }
    .p-button .p-button-label{
        font-size: 15px !important;
    }
}
.lang-es{
    .p-menuitem-text{
        font-size: 16px !important;
    }
    .p-dropdown .p-dropdown-label{
        font-size: 15px !important;
    }
    .p-button .p-button-label{
        font-size: 15px !important;
    }
}

.account-menu-dropdown{
    margin: 1rem 0px 0px;
    padding: 0px;
    border-radius: 0px;
    position: fixed;
    display: block !important;
    top: 75px !important;
    z-index: 99999 !important;
    .p-menu-list{
        .p-menuitem{
            font-size: 15px;
            .p-menuitem-link{
                background: $dropdown-bg;
                .p-menuitem-text{
                    color: $secondary;
                    font-family: $font-secondary;
                }
                .p-menuitem-icon{
                    font-size: 14px;
                    color: $secondary;
                }
                &:hover{
                    background: $dropdown-selected;
                    .p-menuitem-text{
                        color: $white;
                    }
                    .p-menuitem-icon{
                        color: $white;
                    }
                }
            }
        }
    }
    &:before{
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: $white;
        top: -6px;
        left: 25px;
        transform: rotate(45deg);
    }
}
.p-dropdown-panel{
    margin-top: 1rem;
    &.language-dropdown{
        position: fixed;
        display: block !important;
        top: 75px !important;
        z-index: 99999 !important;
    }
    .p-dropdown-items-wrapper{
        border-radius: 0px;
        .p-dropdown-items{
            margin: 0px;
            padding: 0px;
            .p-dropdown-item{
                background: $dropdown-bg;
                font-size: 15px;
                padding: 0.65rem 1.25rem;
                &.p-highlight{
                    background: $dropdown-selected;
                    color: $white;
                }
                &:hover{
                    background: $dropdown-selected;
                    color: $white;
                }
                .country-image{
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    min-width: 22px;
                    min-height: 22px;
                    overflow: hidden;
                    margin-right: 5px;
                    position: relative;
                    top: -3px;
                    border: 1px solid #f1f4f9;
                    .flag{
                        font-size: 30px;
                        position: relative;
                        top: -5px;
                        left: -5px;
                    }
                    .flag-img{
                        object-fit: cover;
                        width: 25px !important;
                        height: 23px !important;
                        position: relative;
                        top: 0px;
                        left: 0px;
                    }
                }
            }
        }
        &:before{
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: $white;
            top: -6px;
            right: 40px;
            transform: rotate(45deg);
        }
    }
}
/* Chrome only: */
@supports (not (-moz-appearance:button)) and (contain:paint) and (-webkit-appearance:none) {
    .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item .country-image{
        top:0px;
        .flag{
            top: -7px;
            left: -8px;
        }
    }
    .plan-section .bundles-section .plan-box .country-image .flag {
        top: -19px !important;
        left: -21px !important;
    }
    .plan-section .bundles-section .country-dropdown .country-image .flag{
        top: -6px !important; 
        left: -7px !important;
    }
}
.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(40, 50, 73, 0.75);
    z-index: 9999;
    .loading-block{
        text-align: center;
        color: $white;
    }
}
.p-dialog{
    .p-dialog-content{
        .paypal-section-button{
            width: 325px;
        }
    }
}
.payment-iframe{
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 650px;
    margin-top: 30px;
}
.is-sticky{
    position: relative;
    z-index: 9999;
    header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        background:$primary;
        padding:15px 165px;
        -webkit-animation-name: animationFade;
        animation-name: animationFade;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.25);
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.25);
    }
    .fixed-height{
        height: 100px;
    }
   .header-navigation .p-menubar .p-menubar-end .p-button{
background: $btnsecondary;
        &:hover{
            background: $btnsecondaryover;
        }
    }
}
.aboutus-header-section{
    .is-sticky{
        .fixed-height{
            height: 102px;
        }  
    }
}

.p-toast{
    opacity: 1;
    max-width: 26rem;
    z-index: 999999 !important;
    .p-toast-message{
        z-index: 9999 !important;
        &.p-toast-message-success{
            background:$white;
            color: $primary;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#2BA079 !important;
                }
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $primary;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#ff5757 !important;
                }
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $primary;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#cc8925 !important;
                }
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $primary;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#5774cd !important;
                }
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-icon{
                width: 25px;
                height: 25px;
                border-radius: 30px;
                color: $white;
                font-size: 14px;
                text-align: center;
                line-height: 18px;
                min-width: 25px;
                padding: 5px;
            }
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}

// **** Footer CSS **** //
footer{
    position: relative;
    .footer-info{
        padding: 50px 165px 10rem;
        background: $primary;
        min-height: 327px;
        .footer-links{
            a{
                display: table;
                margin-bottom: 20px;
                font-size: 16px;
                color: $white;
                opacity: 0.6;
                position: relative;
                font-family: $font-secondary;
                @include transition;
                &:hover{
                    opacity: 1;
                    padding-left: 12px;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        background: $secondary;
                        left: 0px;
                        top: 6px;
                        border-radius: 20px;
                    }
                }
            }
            p{
                opacity: 0.6;
                color: $quinary;
            }
        }
        .footer-logo{
            p{
                margin-top: 3rem;
                margin-bottom: 2rem;
                a{
                    font-size: 18px;
                    color: $quinary;
                    opacity: 0.6;
                }
            }
            ul{
                margin: 12px 0px 0px;
                padding: 0px;
                li{
                    list-style: none;
                    display: inline-block;
                    margin: 0px 5px;
                    .social-image{
                        width: 20px;
                        height: 20px;
                        display: block;
                        @include transition;
                        &.facebook{
                            background: url('../images/facebook.svg');
                        }
                        &.instagram{
                            background: url('../images/instagram.svg');
                        }
                        &.linkedin{
                            background: url('../images/linkedin.svg');
                        }
                        &:hover{
                            background-position: 0px -21px;
                        }
                    }
                }
            }
            .social-span{
                font-size: 14px;
                font-family: $font-secondary;
                color: $white;
                font-weight: 500;
                display: block;
                margin-top: 2rem;
            }
        }
        h2{
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
            margin-top: 10px;
            color: $white;
        }
            }
    .copyrights-div{
        padding: 20px 165px;
        background: $secondary;
        min-height: 90px;
        p{
            margin: 0px;
            color: $white;
            font-size: 14px;
            font-weight: normal;
            font-family: $font-secondary;
            a{
                color: $white;
                &:hover{
                    color: $secondary;
                }
            }
        }
        .left{
            color: $white;
            a{
                font-family: $font-secondary;
                color: $white;
                font-size: 14px;
                &:hover{
                    color: $teritary;
                }
            }
        }
        span{
            font-size: 10px;
            font-family: $font-secondary;
            color: $white;
        }
        ul{
            margin: 0px; 
            padding: 0px;
            li{
                list-style: none;
                margin: 0px 3px;
            }
        }
    }
    .scroll-to-top{
        position: fixed;
        right: 2.5rem;
        bottom: 2.5rem;
        background: $primary;
        width: 2.5rem;
        height: 2.5rem;
        .p-scrolltop-icon{
            color: $white;
            font-size: 14px;
        }
            }
}
.pac-container{
    z-index: 9999;
}
.p-tooltip{
    .p-tooltip-text{
        padding: 0.4rem 0.75rem;
        background: $primary;
    }
    .p-tooltip-arrow{
        margin-left: -0.35rem;
        border-width: 0 0.35em 0.35rem;
    }
}

.applink {
    cursor: pointer;
    height: 45px;
    margin: 0px 5px;
}

.custom-phoneno-field {
    .react-tel-input{
        font-family: $font-secondary;
        .form-control {
            background-color: $input-field;
            border-radius: 8px;
            border: 1px solid $input-border;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
            font-family: $font-secondary;
        }
        .flag-dropdown{
            border-radius: 8px 0 0 8px;
            background-color: $input-field;
            border: 1px solid $input-border;
            .selected-flag{
                border-radius: 8px 0 0 8px;
                width: 51px;
                &.open{
                    &::before{
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        transform: rotate(45deg);
                        background: $white;
                        left: 20px;
                        bottom: -21px;
                        z-index: 6;
                        border: 1px solid #efefef;
                        border-bottom: none;
                        border-right: none;
                    }
                }
                &:hover{
                    background-color: $input-field;
                }
            }
            .country-list{
                font-family: $font-base;
                border-radius: 10px;
                width: 350px;
                padding: 0px 5px;
                max-height: 225px;
                margin: 15px 0 10px -1px;
                &::-webkit-scrollbar {
                    width: 5px;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: $white;
                    border-radius: 5px;
                    margin: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $secondary;
                    border-radius: 5px;
                    padding: 2px;
                }
                .country{
                    padding: 9px 10px 9px 44px;
                }
                .flag{
                    top: 6px;
                }
                .search{
                    .search-emoji{
                        display: none;
                    }
                    .search-box{
                        border-radius: 8px;
                        border: 1px solid $input-border;
                        font-size: 16px;
                        font-weight: 400;
                        padding: 11px 15px 9px 35px;
                        margin-left: 0px;
                        width: 96%;
                        background: #FBFBFB;
                    }
                    &::after{
                        content: "\e908";
                        position: absolute;
                        left: 20px;
                        font-size: 16px;
                        font-family: primeicons;
                        top: 22px;
                    }
                }
            }
            
        }

    }
}
.sticky-window{
    background: $primary;
    box-shadow: 0px 4px 50px 0px rgba(75, 75, 75, 0.25);
    border-radius: 50px;
    color: $white;
    width: 100%;
    max-width: 765px;
    height: auto;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 24px;
    flex-wrap: wrap;
    position: fixed;
    z-index: 2;
    bottom: 1rem;
    left: 0px;
    right: 0px;
    transition: all 0.5s ease-out;
    p{
        margin: 0px;
        font-size: 20px;
        font-weight: 400;
        line-height: 160%;
        font-family: $font-base;
        color: #cdcdcd;
        span{
            font-weight: 500;
            color: $white;
        }
    }
    .p-image{
        img{
            height: 50px;
        }
    }
    &.footer-visible{
        bottom: 7.8rem;
    }
    .sticky-close{
        background: $white;
        padding: 3px;
        border-radius: 4px;
        font-size: 13px;
        position: absolute;
        right: 15px;
        color: $secondary;
        &:hover{
            cursor: pointer;
            color: $mainmenuover;
        }
    }
}

.payment_summary_header{
    min-height: auto !important;
    background: none !important;
}

// Updated on 04-03-2025
.terms-conditions{
    word-wrap: break-word;
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
        li{
            padding: 5px 0px;
        }
    }
    p{
        br{
            display: none;
        }
    }
    .ql-indent-1 {
        padding-left: 30px;
    }
    .ql-indent-2 {
        padding-left: 60px;
    }
    .ql-indent-3 {
        padding-left: 90px;
    }
}
.p-accordion .p-accordion-content{
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
    }
    p{
        br{
            display: none;
        }
    }
    .ql-indent-1 {
        padding-left: 30px;
    }
    .ql-indent-2 {
        padding-left: 60px;
    }
    .ql-indent-3 {
        padding-left: 90px;
    }
}
// Updated on 04-03-2025

@media (min-width:1200px) and (max-width:1460px){
    .header-navigation{
        .p-menubar{
            .p-menubar-root-list{
                .p-menuitem{
                    .p-menuitem-link{
                        padding: 0.75rem 12px;
                        .p-menuitem-text{
                            font-size: 14px;
                        }
                    }
                    &.p-button{
                        margin: 0px 3px;
                        .p-menuitem-link{
                            padding: 20px 30px;
                        }
                    }
                }
            }
            .p-menubar-end{
                .p-button{
                    padding: 18px 20px;
                    margin: 0px 5px;
                    .p-button-label{
                        font-size: 15px;
                    }
                }
                .user-button{
                    min-height:53px;
                    width: 53px;
                }
                .p-dropdown{
                    margin: 0px 8px;
                    padding: 5px 14px 5px 6px;
                    .p-dropdown-label{
                        font-size: 15px; 
                    }
                }
            }
            .logo{
                img{
                    width: 100%;
                    max-width: 125px;
                }
            }
        }
    }
    .lang-tl{
        .p-menuitem-text{
            font-size: 14px !important;
        }
        .p-dropdown .p-dropdown-label{
            font-size: 14px !important;
        }
        .p-button .p-button-label{
            font-size: 14px !important;
        }
    }
    .lang-es{
        .p-menuitem-text{
            font-size: 14px !important;
        }
        .p-dropdown .p-dropdown-label{
            font-size: 14px !important;
        }
        .p-button .p-button-label{
            font-size: 14px !important;
        }
    }
    
    footer{
        .footer-info, .copyrights-div{
            padding: 2rem 40px;
        }
        .footer-info{
            padding: 2rem 40px 10rem;
        }
    }
    .sticky-window.footer-visible {
        bottom: 9rem;
    }
    .aboutus-header-section{
        .is-sticky{
            .fixed-height{
                height: 90px;
            }  
        }
    }
}
@media (max-width:960px){
    .header-navigation{
        .p-menubar{
            .p-menubar-button{
                width: 2.5rem;
                height: 2.5rem;
                background: $secondary;
                position: absolute;
                top: 8px;
                right: 0px;
                .pi-bars{
                    color: $white;
                }
            }
            .p-menubar-root-list{
                padding: 15px;
                border-radius: 5px;
                width: 100%;
                max-width: 320px;
                right: 0px;
                .p-menuitem{
                    margin: 1px 0;
                    .p-menuitem-link{
                        .p-menuitem-text{
                            font-size: 16px;
                            color: $primary;
                        }
                    }
                    &.p-button {
                        margin-top: 10px;
                        .p-menuitem-link{
                            padding: 8px 30px;
                            min-width: 135px;
                            display: block;
                            margin-left: 1rem;
                        }
                    }
                    &.login-btn{
                        margin-left: 0px;
                        margin-top: 5px;
                    }
                    &.menu-item-pricing{
                        margin-left: 5px;
                    }
                    &.menu-item-contact{
                        margin-right: 0px; 
                    }
                }
            }
            .p-menubar-end{
                text-align: center;
                margin-right: 3rem;
                .p-dropdown{
                    padding: 0px 10px;
                    margin: 0px 5px;
                    .p-dropdown-label{
                        font-size: 16px;
                                            }
                }
                .p-button{
                    margin: 0px 5px;
                    padding: 12px 15px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .user-button{
                    min-height: 50px;
                    width: 50px;
                }
            }
        }
    }
    footer{
        .footer-info, .copyrights-div{
            padding: 25px 20px;
            h2{
                font-size: 18px;
            }
            .footer-links{
                a,p{
                    font-size: 15px;
                    margin-bottom: 15px;
                }
            }
        }
        .copyrights-div{
            justify-content: center !important;
            display: block;
            text-align: center;
            P{
                margin-bottom: 10px;
            }
            ul{
                justify-content: center;
            }
        }
        .scroll-to-top{
            right: 1rem;
            bottom: 3rem;
            width: 2rem;
            height: 2rem;
        }
    }
    .aboutus-header-section{
        .is-sticky{
            .fixed-height{
                height: 68px;
            }  
        }
    }
    .p-dropdown-panel{
        margin-top: 1rem;
        &.language-dropdown{
            top: 50px !important;
        }
    }
}
@media (min-width:769px) and (max-width:960px){
    footer{
        .copyrights-div{
            justify-content: space-between !important;
            p{
                margin-bottom: 0px;
            }
            .left{
                justify-content: center;
            }
        }
        .footer-info{
            padding: 25px 20px 8rem;
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    footer{
        .footer-info, .copyrights-div{
            padding: 25px 20px;
        }
        .footer-info{
            padding: 25px 20px 8rem;
        }
    }
    .sticky-window.footer-visible {
        bottom: 7.5rem;
    }
    .header-navigation{
        .p-menubar{
            .p-menubar-start{
                .logo{
                    img{
                        width: 100%;
                        max-width: 115px;
                    }
                }
            }
            .p-menubar-root-list{
                .p-menuitem{
                    margin: 3px;
                    .p-menuitem-link{
                        padding: 0.75rem 0.6rem;
                        .p-menuitem-text{
                            font-size: 15px;
                        }
                    }
                    &.p-button{
                        margin: 0px 3px;
                        .p-menuitem-link{
                            padding: 14px 25px;
                        }
                    }
                    &.menu-item-pricing{
                        margin-left: 5px;
                    }
                }
            }
            .p-menubar-end{
                text-align: center;
                .p-dropdown{
                    padding: 0px 10px 0px 4px;
                    margin: 0px 3px;
                    .p-dropdown-label{
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
                .p-button{
                    margin: 0px 3px;
                    padding: 12px 14px;
                    .p-button-label{
                        font-size: 14px;
                    }
                }
                .user-button{
                    min-height: 50px;
                    width: 50px;
                }
            }
        }
    }
    .aboutus-header-section{
        .is-sticky{
            .fixed-height{
                height: 90px;
            }  
        }
    }
}
@media (max-width:480px){
    .header-navigation{
        .p-menubar{
            .p-menubar-start{
                .logo{
                    img{
                        width: 100%;
                        max-width: 120px;
                    }
                }
            }
            .p-menubar-end{
                .p-dropdown{
                    margin: 5px 3px;
                }
                .p-button{
                    margin: 5px 3px;
                }
            }
            .p-menubar-button{
                top: 14px;
            }
        }
    }
    .p-dialog{
        .p-dialog-content{
            .paypal-section-button{
                width: 100%;
                max-width: 325px;
            }
        }
    }
    .is-sticky .fixed-height {
        height: 78px !important;
    }
    .sticky-window.footer-visible {
        bottom: 10.5rem !important;
    }
    .applink{
        height: 40px;
    }
}
@media (max-width: 768px) {
    .payment-iframe {
      height: 450px;
      margin-top: 20px;
    }
    .p-toast{
        width: 95%;
        left: 0px;
        right: 0px !important;
        margin: 0 auto;
        top: 10px !important;
    }
    .sticky-window{
        width: 90%;
        text-align: center;
        display: block;
        padding: 10px;
        bottom: 0.5rem;
        p{
            margin-bottom: 8px;
            font-size: 14px;
        }
       .p-image img{
        margin: 0px 6px;
        height: 34px;
       }
       &.footer-visible {
            bottom: 9.5rem;
        }
    }
    footer{
        .copyrights-div{
            .left{
                justify-content: center;
            }
        }
        .footer-info{
            padding: 25px 20px 8rem;
            min-height: 270px;
        }
    }
    .terms-conditions{
        .ql-indent-1 {
            padding-left: 20px;
        }
        .ql-indent-2 {
            padding-left: 40px;
        }
        .ql-indent-3 {
            padding-left: 60px;
        }
    }
    .p-accordion .p-accordion-content{
        .ql-indent-1 {
            padding-left: 20px;
        }
        .ql-indent-2 {
            padding-left: 40px;
        }
        .ql-indent-3 {
            padding-left: 60px;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1360px) {
    .payment-iframe {
      height: 600px;
      margin-top: 20px;
    }
}

.green-dot {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 10px;
}

@media (min-width:1460px) and (max-width:1580px){
    .header-navigation{
        .p-menubar{
            .p-menubar-root-list{
                .p-menuitem{
                    .p-menuitem-link{
                        padding: 0.75rem 18px;
                        .p-menuitem-text{
                            font-size: 16px;
                        }
                    }
                    &.p-button{
                        margin: 0px 3px;
                        .p-menuitem-link{
                            padding: 20px 30px;
                        }
                    }
                }
            }
            .p-menubar-end{
                .p-button{
                    padding: 18px 20px;
                    margin: 0px 5px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .user-button{
                    min-height: 60px;
                    width: 60px;
                }
                .p-dropdown{
                    margin: 0px 8px;
                    padding: 5px 14px;
                    .p-dropdown-label{
                        font-size: 16px; 
                    }
                }
            }
        }
    }
    .steps-section .relative .steps-box h3{
        font-size: 20px;
        line-height: 30px;
    }
}
@media (min-width:1200px) and (max-width:1230px){
    .header-navigation .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link {
        padding: 0.75rem 10px;
    }
    .header-navigation .p-menubar .p-menubar-end .p-button{
        padding: 18px 15px;
    }
    .header-navigation .p-menubar .p-menubar-end .p-dropdown {
        margin: 0px 5px;
        padding: 5px 12px;
    }
}

// Redirecting CSS

.redirect-block{
    height: calc(100vh - 418px);
    background: #150e1c;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 3rem;
    .pi{
        font-size: 25px;
    }
    h2{
        font-size: 24px;
        margin: 15px 0px 5px;
    }
    p{
        padding: 0px 25px;
        font-size: 16px;
        line-height: 150%;
        a{
            color: $white;
            text-decoration: underline;
        }
    }
}
.registration-block{
    background: #fff;
    color: $white;
    padding-bottom: 5rem;
    .top-blok{
        background: #150e1c;
        padding: 2rem 2rem 4rem; 
        h1{
            margin: 0px;
            color: $white;
            font-size: 18px;
            text-align: center;
        }
        .logo{
            margin-bottom: 3rem;
            display: block;
        }
    }
    
    .contact_form{
        padding: 2rem;
        border:1px solid $white;
        margin: -2.5rem 1.5rem;
        border-radius: 30px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        border: 1px solid $quaternary;
        .p-inputtext{
            border-radius: 8px;
            border: 1px solid $input-border
        }
        label{
            color: $primary;
        }
        .submit-button{
            margin-top: 1rem;
            padding: 14px 40px;
            box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
            background: $primary;
            border: none;
            border-radius: 50px;
            width: 100%;
            .p-button-label{
                font-size: 16px;
                font-family: $font-base;
                font-weight: 600;
            }
            &:hover{
                background: $secondary;
            }
        }
    }
    .error-message-device{
        color: $error-msg;
        font-size: 13px;
        margin: 5px 2px;
    }
}
.p-dialog-mask{
    z-index: 99999 !important;
}
.asterisk-unlimited {
    color: $error-msg;
}
.tooltip-block{
    width: 100%;
    max-width: 225px;
    font-size: 13px;
    line-height: 140%;
}
@media (min-width:1750px) and (max-width:1800px){
    .header-section .header-navigation{
        padding: 15px 125px !important;
    }
    .inner-header-section.aboutus-header-section .header-navigation{
        padding: 15px 125px !important;
    }
    .inner-header-section.aboutus-header-section .is-sticky header{
        padding: 0px 0px !important;
    }
    .header.is-sticky header{
        padding: 15px 125px;
    }
    .contact-header{
        padding: 15px 125px !important;
    }
}