@import '../../assets/scss/variables';

.notifications-list{
    .bg-contain{
        flex-direction: column;
        p{
            max-width: 100% !important;
        }
        h1 {color: #160F1F; font-size: 46px; font-weight: 400; line-height: 52px; letter-spacing: -1.38px; margin: 25px 0px 0px;} 
        h1 span {color: #0075FF !important; font-size: 46px !important; font-weight: 700;} 
        h2 {color: #160F1F; font-size: 46px !important; font-weight: 400; line-height: 52px; letter-spacing: -1.38px; margin: 5px 0px 0px;}
        h2 span{font-size: 46px !important; color: #000 !important;}
        h3 {color: #160F1F !important; font-size: 32px !important; font-weight: 400 !important; line-height: 45px; letter-spacing: -0.93px; margin: 5px 0px; padding: 0px 100px;} 
        .button {display: inline-block; background: #0075FF; color: #ffffff !important; padding: 0.9rem 2.5rem; text-decoration: none; border-radius: 60px;font-size: 18px; font-weight: 700; line-height: 28px; letter-spacing: -0.54px;width: 125px;text-align: center;width: auto !important;} 
        .button:hover {background: #160F1F; opacity: 1;} 
        .code {border-radius: 120px; background: #CFBCBC; width: 300px; height: auto; margin: 10px auto 30px; text-align: center; color: #160F1F; font-size: 33px; font-weight: 400; line-height: 60px; letter-spacing: -0.5px; padding: 12px 0px;}
        .container {width: 100%; max-width: 850px; margin: 20px auto; background: #ffffff; padding: 20px 0px; border-radius: 60px; box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);border: 1px solid #efefef;} 
        .content {padding: 30px 0px; text-align: left; width: 100%; max-width: 635px; margin: 0 auto;} 
        .custom-border {border-bottom: 1px solid #E7DEDE;} 
        .details-ul .email-li{text-align: right;width: 85%;word-wrap: break-word;line-height: 18px;}
        .details-ul {margin: 5px 25px; padding: 16px 0px; background: #fff;} 
        .details-ul li {list-style: none; color: #160F1F;font-size: 18px; font-weight: 700; line-height: 25px; letter-spacing: -0.54px; float: right;} 
        .footer {text-align: center; font-size: 12px; color: #666; padding: 10px;} 
        .footer-links li a:hover {color: #ff1900; opacity: 1;}
        .footer-ul {margin: 0px; background: #160F1F !important;} 
        .header {text-align: center;} 
        .header-ul .data-plan-li{text-align: right;width: 78%;word-wrap: break-word;}
        .header-ul {margin: 0px 0px 10px; padding: 15px 25px; border-radius: 95px; background: #0075FF;} 
        .header-ul li {list-style: none; color: #fff; font-size: 18px; font-weight: 700; line-height: 25px; letter-spacing: -0.54px; float: right;} 
        .info-block {padding: 10px 45px; margin-top: 35px;}
        .info-block h3 {margin: 15px 0px 0px !important; color: #FF1D00 !important; font-size: 20px !important; font-weight: 700; line-height: 28px; letter-spacing: -0.6px; text-align: center;} 
        .info-block p {color: #160F1F; font-size: 20px; font-weight: 400; line-height: 28px; letter-spacing: -0.6px; text-align: center; margin: 0px;} 
        .install-block {display: flex; align-items: center; justify-content: space-between; padding: 38px 0px;} 
        .ios {background: #3E2F50;} 
        .manual-details-ul {background: #E5DBDB;} 
        .manual-details-ul li {color: #160F1F;} 
        .orderID {color: #CFBCBC !important; font-size: 18px !important; font-weight: 400; line-height: 60px !important; letter-spacing: -0.54px; margin: 0px;} 
        .orderID span {color: #3E2F50 !important; font-weight: 600;font-size: 18px !important;}
        .qrcode {border-radius: 24px; border: 1px solid #F1EBEB; padding: 15px; width: 138px;display: none;} 
        .registration-h2{color: #160F1F; font-size: 32px; font-weight: 400; line-height: 45px; letter-spacing: -0.9px; margin: 15px 0px 0px; padding: 0px 75px;}
        .registration-h2 a { color: #0075FF !important; }
        .social-ul {margin: 0px; padding: 0px;} 
        .social-ul li {list-style: none; display: inline-block; margin: 0px 10px;} 
        .social-ul.footer-links {margin: 2rem 0rem 0rem; padding: 0px;} 
        .social-ul.footer-links li {color: #160F1F;} 
        .social-ul.footer-links li a {color: #160F1F; font-size: 14px; font-weight: 400; line-height: 28px; letter-spacing: -0.42px;} 
        li.fontWeight-m {font-weight: 400; float: none; display: inline-block;}
        .with-partners-logo li{list-style: none;}
        /* Responsive CSS */
        @media (max-width:768px){
            h1 {font-size: 35px !important;line-height: 52px;margin: 20px 0px 0px;}
            h1 span{font-size: 35px !important;}
            h2 {font-size: 32px !important;line-height: 38px;margin: 0px 0px 0px;}
            h2 span{font-size: 32px !important;}
            h3 {font-size: 24px !important;line-height: 36px;margin: 15px 0px 0px;padding: 0px 10px;}
            .button{font-size: 13px;padding: 0.6rem 0.7rem;width: 85px;}
            .container{width: 100%;margin: 10px auto;padding: 0px;border-radius: 15px;border: none;}
            .content{padding: 20px 0px;}
            .details-ul {margin: 5px 15px;padding: 15px 0px;}
            .details-ul li{font-size: 14px;}
            .header-ul li{font-size: 14px;}
            .header-ul{padding: 15px 15px;}
            .info-block {padding: 35px 10px 10px;margin-top:5px;}
            .info-block p{font-size: 18px;}
            .qrcode{border-radius: 10px;padding: 5px;width: 94px;}
            .registration-h2{font-size: 24px;line-height: 35px;padding: 0px 10px;}
        }
        @media (max-width:390px){
            .header-ul li {font-size: 13px;line-height: 17px;}
        }
        @media (max-width:340px){
            .install-block table tr td {display:block; text-align: center; margin: 6px 0px;}
            .header-ul .data-plan-li{width: 75%;}
        }
    }
}