@import '../../assets/scss/variables';

.forms-dialog{
    width: 100%;
    max-width: 675px;
    .p-dialog-header{
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 10px;
        z-index: 1;
    }
    .p-dialog-content{
        padding: 0px;
        border-radius: 2px;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: $white;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
            background: $secondary;
            padding: 2px;
            border-radius: 5px;
        }
        .form-section{
            margin-top: 2rem;
            p{
                font-size: 14px;
                font-weight: 400;
                margin: 0px;
                font-family: $font-secondary;
                a{
                    color: $teritary;
                    &:hover{
                        color: $secondary;
                        text-decoration: underline;
                    }
                }
            }
            .form{
                width: 100%;
                max-width: 450px;
                margin: 1rem auto;
                font-family: $font-base;
                h3{
                    font-size: 24px;
                    margin: 0px 0px 25px;
                    font-weight: 600;
                    color: #202020;
                }
                .input-field{
                    margin-bottom: 20px;
                    .p-inputtext{
                        border: 1px solid #DCDCDC;
                        width: 100%;
                        height: 48px;
                        border-radius: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        &::placeholder{
                            color: #898989;
                        }
                    }
                    &.custom-input-field{
                        margin-bottom: 12px;
                    }
                }
                .error-message{
                    color: $error-msg;
                    font-size: 13px;
                    margin: 5px 2px;
                }
                .forgot-btn{
                    background: transparent;
                    border: none;
                    padding: 0px;
                    color: #202020;
                    display: block;
                    width: fit-content;
                    font-family: $font-secondary;
                    .p-button-label{
                        font-weight: 400;
                        font-size: 14px;
                    }
                    &:hover{
                        color: $teritary;
                    }
                }
                .login-btn{
                    border-radius: 30px;
                    background: $secondary;
                    width: 100%;
                    border: none;
                    margin-top: 2rem;
                    height: 48px;
                    .p-button-label{
                        font-weight: 600;
                        font-size: 14px;
                    }
                    &:hover{
                        background: $teritary;
                    }
                    &.signup-btn{
                        margin-top: 0rem;
                    }
                }
                .checkbox{
                    margin-top: 1rem;
                    .p-checkbox{
                        position: relative;
                        top: 4px;
                    }
                    label{
                        font-size: 12px;
                        color: #898989;
                        margin-left: 0.8rem;
                        font-family: $font-secondary;
                    }
                }
                .or-span{
                    display: block;
                    color: #999;
                    font-size: 14px;
                    position: relative;
                    width: 100%;
                    margin: 1.5rem auto;
                    text-align: center;
                    &::before{
                        content: '';
                        width:35%;
                        height: 1px;
                        background: #DCDCDC;
                        top:8px;
                        left: 5px;
                        position: absolute;
                    }
                    &::after{
                        content: '';
                        width: 35%;
                        height: 1px;
                        background: #DCDCDC;
                        top:8px;
                        right:5px;
                        position: absolute;
                    }
                }
                .social-button{
                    align-items: center;
                    background: $white;
                    border: 1px solid #e5e5e5;
                    border-radius: 20px;
                    box-shadow: 0 0 10px 10px rgba(0,0,0,.02);
                    display: flex;
                    height: 48px;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto 12px;
                    max-width: 225px;
                    width: 100%;
                    .googleBtnText,.appleBtnText{
                        font-size: 14px;
                        margin-left: 10px;
                        line-height: 0px;
                    }
                    .p-image{
                        line-height: 0px;
                        img{
                            width: 20px;
                        }
                        &.applePay{
                            img{
                                width: 15px;
                            }  
                        }
                    }
                    &:hover{
                        cursor: pointer;
                        box-shadow: none;
                    }
                }
                .overlay{
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.65);
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 0px;
                    bottom: 0px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .loading-block{
                        color: $white;
                        p{
                            margin: 5px 0px;
                        }
                    }
                }
            }
            .form-footer{
                width: 100%;
                max-width: 420px;
                margin: 2rem auto;
                p{
                    font-size: 13px;
                    line-height: 16px;
                    a{
                        color: $teritary;
                    }
                }
            }
            &.forgot-password-section{
                min-height: 550px;
                display: flex;
                align-items: center;
                .form{
                    h3{
                        margin: 0px 0px 1rem;
                    }
                    p{
                        font-size: 14px;
                        color: #5A5A5A;
                        margin-bottom: 1rem;
                        line-height: 24px;
                    }
                }
            }
            .form-top-link{
                padding-right:4rem;
            }
        }
        .loading-div{
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.64);
            position: absolute;
            display: flex;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            z-index: 999;
            align-items: center;
            justify-content: center;
            .pi-spin{
                width: 50px;
                height: 50px;
                color: #fff;
                font-size: 50px;
            }
        }
    }
    .login-bg{
        background: url(../../assets/images/login-bg.png), lightgray 50% / cover no-repeat;
        background-color: #d8e4e9;
        height: 100%;
        text-align: center;
        padding: 4rem 2rem;
        .p-image{
            img{
                width: 250px;
            }
        }
    }
    &.payment-dialog{
        width: 100%;
        max-width: 75%;
        .p-dialog-content{
            padding: 2rem;
            .payment-heading{
                margin: 5px 0px;
                font-size: 26px;
            }
            .logo{
                img{
                    width: 100%;
                    max-width: 150px;
                }
            }
            .payment-options{
                padding: 2rem 2rem;
                .p-steps{
                    margin-bottom: 4rem;
                    .p-steps-item{
                        .p-menuitem-link{
                            .p-steps-number{
                                background: $quaternary;
                                font-size: 15px;
                            }
                            .p-steps-title{
                                font-size: 15px;
                            }
                        }
                        &.p-highlight{
                            .p-menuitem-link{
                                .p-steps-number{
                                    background: $white;
                                    border: 2px solid $secondary;
                                    color: $secondary;
                                }
                            }
                        }
                        &:first-child{
                            &::before{
                                width: 55%;
                                left: inherit;
                                right: 0px;
                            }
                        }
                        &:last-child{
                            &::before{
                                width: 55%;
                            }
                        }
                        &::before{
                            border-top: 2px solid $quaternary;
                        }
                    }
                }
                .left{
                    padding: 0px 5rem 0px 3rem;
                    .payment-button{
                        margin: 0px;
                        display: flex;
                        margin: 0px;
                        display: flex;
                        align-items: center;
                        background: $quaternary;
                        padding: 1rem 1rem;
                        border: 1px solid $quaternary;
                        cursor: pointer;
                        border-radius: 5px;
                        margin-bottom: 1.5rem;
                        height: 55px;
                        .p-image{
                            margin-right:0.8rem;
                            line-height: 0px;
                            &.gpay-img{
                                img{
                                    width:27px;
                                }
                            }
                            &.payment-img{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img{
                                    width: 25px;
                                }
                            }
                        }
                        .payment-text{
                            margin-right:0.8rem;
                            font-weight: 600;
                            font-size: 16px;
                            &.payment-options-text{
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                        &:hover{
                            border: 1px solid $secondary;
                            background: $secondary;
                            .payment-text{
                                color: $white;
                            }
                            .stripe-img{
                                img{
                                    filter: brightness(0) invert(1);
                                }
                            }
                            .p-image{
                                img{
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }
                        &.payment-active{
                            background: $secondary;
                            border: 2px solid $secondary;
                            .payment-text{
                                color: $white;
                            }
                            .p-image{
                                img{
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }
                    }
                    .payment-form{
                        margin-top: 4rem;
                        h3{
                            font-size: 20px;
                            font-weight: 600;
                        }
                        .p-component{
                            border-radius: 6px;
                            background: #F7F7F7;
                            border: none;
                            font-size: 16px;
                            padding: 15px 20px;
                            border: none !important;
                        }
                        .p-input-icon-right{
                            .pi-map-marker{
                                top: 40%;
                            }
                        }
                        .continue-button{
                            border-radius: 24px;
                            background: $teritary;
                            box-shadow: 0px 24px 40px -12px rgba(0, 0, 0, 0.08);
                            margin-top: 1rem;
                            margin-bottom: 0px;
                            width: 100%;
                            max-width: 100%;
                            .p-button-label{
                                font-size: 15px;
                                font-weight: 600;
                            }
                            &:hover{
                                background: $secondary;
                            }
                        }
                        .card-number{
                            background: #F7F7F7;
                            border-radius: 6px;
                            padding: 15px;
                            iframe{
                                border-radius: 6px;
                                border: none;
                                font-size: 16px;
                            }
                        }
                        .card-month{
                            background: #F7F7F7;
                            border-radius: 6px;
                            padding: 4px 15px;
                            iframe{
                                border-radius: 6px;
                                border: none;
                                font-size: 16px;
                            }
                        }
                        .custom-phoneno-field{
                            width: 100%;
                            .react-tel-input{
                                .form-control {
                                    width: 100%;
                                    height: 50px;
                                    border-radius: 6px;
                                    border: none;
                                    background: #F7F7F7;
                                }
                                .flag-dropdown{
                                    border-radius: 6px 0 0 6px;
                                    border: none;
                                    background-color: $quaternary;
                                    .selected-flag{
                                        border-radius: 6px 0 0 6px;
                                        background-color: $quaternary;
                                    }
                                    &:hover{
                                        background-color: $quaternary;
                                    }
                                }
                            }
                            .error-message{
                                top: 2px;
                            }
                        }
                    }
                    .disablePayments{
                        opacity: .5;
                        pointer-events: none;
                    }
                    .order-btn{
                        border-radius: 30px;
                        background: $secondary;
                        width: 100%;
                        max-width: 250px;
                        border: none;
                        margin-top: 1rem;
                        height: 48px;
                        .p-button-label{
                            font-weight: 600;
                            font-size: 14px;
                        }
                        &:hover{
                            background: $primary;
                        }
                        &.signup-btn{
                            margin-top: 0rem;
                        }
                    }
                }
                .right{
                    padding: 0px 3rem 0px 5rem;
                    h2{
                        margin: 0px 0px 20px;
                    }
                    .bundle-list{
                        .p-image{
                            img{
                                width: 100%;
                                max-width: 70px;
                            }
                        }
                        h4{
                            margin: 0px;
                            font-size: 16px;
                            width: 100%;
                        }
                        span{
                            font-size: 14px;
                            &.trash{
                                border: 1px solid $input-border;
                                border-radius: 6px;
                                width: 32px;
                                height: 32px;
                                text-align: center;
                                line-height: 34px;
                                cursor: pointer;
                                display: block;
                                .pi{
                                    color: $teritary;
                                    font-size: 13px;
                                }
                                &:hover{
                                    background: $teritary;
                                    border: 1px solid $teritary;
                                    .pi{
                                        color: $white;
                                    }
                                }
                            }
                            &.error-msg{
                                font-size: 13px;
                                color: $error-msg;
                                position: absolute;
                                bottom: -12px;
                                left: 8px;
                            }
                        }
                        h5{
                            margin: 0px;
                            font-size: 22px;
                        }
                        label{
                            font-size: 14px;
                            color: #666;
                            margin-bottom: 8px;
                            display: block;
                        }
                        .p-component{
                            border-radius: 6px;
                            border: none;
                            font-size: 16px;
                            height: 48px;
                            width: 100%;
                        }
                        .code-apply{
                            width: 100%;
                            height: 48px;
                            border-radius: 8px;
                            background: #F7F7F7;
                            padding: 0px 1rem;
                            margin-bottom: 10px;
                            position: relative;
                            span{
                                font-size: 14px;
                                .font-italic{
                                    color: $primary;
                                    margin-left: 5px;
                                }
                            }
                            .pi-times{
                                color: $secondary;
                                font-size: 14px;
                                cursor: pointer;
                                padding: 5px;
                                border-radius: 5px;
                                position: absolute;
                                right: 0px;
                                &:hover{
                                    background: $secondary;
                                    color: $white;
                                }
                            }
                        }
                        .apply-button{
                            border-radius: 24px;
                            border:1px solid $secondary;
                            box-shadow: 0px 24px 40px -12px rgba(0, 0, 0, 0.08);
                            margin-bottom: 0px;
                            width: 100%;
                            max-width: 250px;
                            background: #F7F7F7;
                            .p-button-label{
                                font-size: 15px;
                                font-weight: 600;
                                color: $secondary;
                            }
                            &:hover{
                                background: $secondary !important;
                                .p-button-label{
                                    color: $white;
                                }
                            }
                        }
                        .total-price{
                            ul{
                                margin: 0px;
                                padding: 0px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 1rem;
                                li{
                                    list-style: none;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #666;
                                    b{
                                        font-size: 20px;
                                        font-weight: 700;
                                        color: $secondary;
                                    }
                                }
                            }
                        }
                        .bundle-info{
                            ul{
                                margin: 0px 0px 15px;
                                padding: 0px;
                                font-family: $font-secondary;
                                li{
                                    list-style: none;
                                    &:first-child{
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
                .error-message{
                    font-size: 14px;
                    color: $error-msg;
                    position: relative;
                    top: -10px;
                }
                .compatibility-box{
                    margin-bottom: 2.5rem;
                    font-family: $font-secondary;
                    .field-checkbox{
                        .p-checkbox{
                            .p-checkbox-box{
                                &.p-highlight{
                                    background: $teritary;
                                    border: $teritary;
                                }
                            }
                        }
                        .confirmationText{
                            font-size: 16px;
                            .device-link{
                                color: $teritary;
                                border-bottom: 1px solid $teritary;
                                padding-bottom: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.paymentsummary-qr-section{
    text-align: center;
    padding: 4rem 2rem !important;
    .scan-text{
        display: block;
        color: $primary;
        font-size: 15px;
        a{
            color: $white;
            border-bottom:1px solid $white;
        }
        .payer-email {
            color: $secondary !important;
        }
    }
    p{
        color: $primary;
        font-size: 15px;
    }
    .qr-code{
        padding: 5px;
        background: $primary;
        border-radius: 10px;
        margin: 2rem 0rem !important;
    }
    .download-button{
        border-radius: 24px;
        border: none;
        box-shadow: 0px 24px 40px -12px rgba(0, 0, 0, 0.08);
        margin-bottom: 0px;
        width: 100%;
        max-width: 200px;
        background: $secondary;
        .p-button-label{
            font-size: 15px;
            font-weight: 600;
            color: $white;
        }
        &:hover{
            background: $primary;
        }
    }
    .go-button{
        background: transparent;
        border: none;
        padding: 0px;
        margin-top: 1.2rem;
        .p-button-label{
            font-size: 15px;
            font-weight: 600;
            color: $primary;
            padding-bottom: 6px;
            border-bottom: 1px solid $primary;
        }
        .p-button-icon{
            color: $primary;
            font-size: 14px;
        }
        &:hover{
            background: none;
            .p-button-label{
                color: $secondary;
                border-bottom: 1px solid $secondary;
            }
            .p-button-icon{
                color: $secondary;
            }
        }
    }
}

.paypal-dialog{
    .loading{
        background: $primary;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        .pi-spinner{
            color: $white;
            font-size: 30px;
            font-weight: 600;
        }
    }
    .p-dialog-header{
        padding: 0px;
        position: absolute;
        right: -15px;
        top: -15px;
        border-radius: 30px;
        background: $secondary;
        .p-dialog-header-icons{
            width: 1.6rem;
            height: 1.6rem;
            .p-dialog-header-icon{
                border-radius: 30px !important;
                color: $white;
                width: 1.6rem;
                height: 1.6rem;
                svg{
                    width: 13px;
                }
            }
        }
        &:hover{
            .p-dialog-header-icons{  
                .p-dialog-header-icon{
                    color: $primary;
                }
            }
        }
    }
    .p-dialog-content{
        padding: 1.5rem;
        border-radius: 2px;
    }
}
.hide-payment{
    visibility: hidden;
}

.promocode-modal-dialog{
    .p-component{
        border-radius: 6px;
        background: #F7F7F7;
        border: none;
        font-size: 16px;
        height: 48px;
        width: 100%;
    }
    .confirm_order_button{
        border-radius: 24px;
        border: none;
        box-shadow: 0px 24px 40px -12px rgba(0, 0, 0, 0.08);
        margin-bottom: 0px;
        width: 100%;
        max-width: 200px;
        background: $secondary;
        .p-button-label{
            font-size: 15px;
            font-weight: 600;
            color: $white;
        }
        &:hover{
            background: $primary;
        }
    }
    .error-message{
        font-size: 13px;
        color: $error-msg;
    }
}

.paymongo-dailog{
    width: 90%;
    max-width: 390px;
    button{
        width: 100%;
        border-radius: 5px;
        background: $quaternary;
        border: 1px solid #EEEEEE;
        margin: 8px 0px;
        &:hover{
            background: $btnprimary !important;
        }
        .p-image{
            line-height: 0px;
        }
    }
}
.payment-loading{
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading-block{
        text-align: center;
    }
}

// Responsive CSS

@media (max-width:960px){
    .forms-dialog{
        width: 95% !important;
        max-width: 95% !important;
        .login-bg{
            padding: 2rem 0rem;
            .p-image{
                img{
                    width: 100%;
                    max-width: 150px;
                }
            }
        }
        .p-dialog-header{
            border-radius: 30px;
            right: 5px;
            top: 5px;
            padding: 0px;
            .p-dialog-header-icon{
                width: 2rem;
                height: 2rem;
            }
        }
        .p-dialog-content{
            .form-section{
                margin-top: 2rem;
                padding: 0px 1.5rem;
                .form{
                    margin: 2rem auto;
                    .mobile-flex{
                        display: block !important;
                    }
                    .social-button{
                        max-width: 250px;
                    }
                }
                .form-top-link{
                    padding-right: 0px;
                    text-align: center !important;
                }
            }
        }
        &.payment-dialog{
            .p-dialog-content{
                .payment-options{
                    padding: 0rem;
                    .left{
                        padding: 0px 0rem;
                    }
                    .right{
                        padding: 0px;
                    }
                    .compatibility-box{
                        .field-checkbox{
                            .confirmationText{
                                line-height: 20px;
                                font-size: 16px;
                            }
                        }
                    }
                    .border-right-1{
                        border: none !important;
                    }
                }
            }
        }
    }
}
@media (min-width:769px) and (max-width:960px){
    .forms-dialog{
        .p-dialog-content{
            .form-section{
                .form{
                    .mobile-flex{
                        display: flex !important;
                    }
                }
            }
        }
        &.payment-dialog{
            .p-dialog-content{
                .payment-options{
                    padding: 0rem;
                    .left{
                        padding: 0px 0rem;
                        .payment-button{
                            width: 90%;
                        }
                        .payment-form{
                            padding: 0px 2rem 0px 0px;
                        }
                    }
                    .right{
                        padding: 0px;
                    }
                    .border-right-1{
                        border: none !important;
                    }
                }
            }
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .forms-dialog{
        width: 90% !important;
        .login-bg{
            padding: 2rem 0rem;
            .p-image{
                img{
                    width: 100%;
                    max-width: 150px;
                }
            }
        }
        .p-dialog-header{
            border-radius: 30px;
            right: 8px;
            top: 8px;
            .p-dialog-header-icon{
                width: 1rem;
                height: 1rem;
            }
        }
        .p-dialog-content{
            .form-section{
                margin-top: 1.5rem;
                .form{
                    .mobile-flex{
                        display: flex !important;
                    }
                    .input-field{
                        .p-inputtext{
                            height: 45px;
                        }
                    }
                    .or-span{
                        margin: 1.2rem auto;
                    }
                }
                .form-footer{
                    margin: 1.8rem auto;
                }
            }
        }
        &.payment-dialog{
            .p-dialog-content{
                .payment-options{
                    padding: 0rem;
                    .left{
                        padding: 0px 0rem;
                        .payment-button{
                            width: 90%;
                        }
                        .payment-form{
                            padding: 0px 3.5rem 0px 0px;
                        }
                    }
                    .right{
                        padding: 0px;
                    }
                    .border-right-1{
                        border: none !important;
                    }
                }
            }
        }
    }
}
@media (min-width:1200px) and (max-width:1460px){
    .forms-dialog{
        .p-dialog-content{
            .form-section{
                margin-top: 1.5rem;
                .form{
                    .mobile-flex{
                        display: flex !important;
                    }
                    .input-field{
                        .p-inputtext{
                            height: 45px;
                        }
                    }
                    .or-span{
                        margin: 1.2rem auto;
                    }
                    .social-button{
                        margin: 0 auto 5px;
                        height: 45px;
                    }
                }
                .form-footer{
                    margin: 1.8rem auto;
                }
            }
        }
        &.payment-dialog{
            .p-dialog-content{
                .payment-options{
                    padding: 0rem;
                    .left{
                        padding: 0px 0rem;
                        .payment-button{
                            width: 94%;
                        }
                        .payment-form{
                            padding-right: 6%;
                        }
                    }
                    .right{
                        padding: 0px 1rem;
                    }
                }
            }
        }
    }
}
@media (max-width:420px){
    .promocode-modal-dialog{
        width: 100%;
        max-width: 95%;
    }
    .paypal-dialog{
        width: 100%;
        max-width: 85%;  
    }
}
@media (max-width:768px){
    .forms-dialog.payment-dialog{
        .p-dialog-content{
            .payment-options{
                .right{
                    margin-top: 2rem;
                    .bundle-list{
                        h4{
                            font-size: 15px;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
        .compatibility-section{
            .compatibility-box{
                .field-checkbox{
                    align-items:start;
                }
            }
        }
    }
    .forms-dialog.payment-dialog{
        .p-dialog-content{
            padding: 1.5rem;
            .payment-options{
                .right{
                    .bundle-list{
                        .code-apply{
                            padding: 0px 10px;
                        }
                    }
                }
            }
        }
    }
}
.form-section{
    padding: 15px 0px;
    .form{
            .custom-phoneno-field{
                .react-tel-input{
                    font-family: $font-secondary;
                    .form-control {
                        border-radius: 8px;
                        border: 1px solid $input-border;
                        height: 50px;
                        font-size: 16px;
                        font-weight: 400;
                        width: 100%;
                        font-family: $font-secondary;
                    }
                    .flag-dropdown{
                        border-radius: 8px 0 0 8px;
                        .selected-flag{
                            border-radius: 8px 0 0 8px;
                        }
                    }
                }
            }
    }
}

.emailLabel { 
    color: #30313d;
}
.debit-card-section{
    width: 100%;
    max-width: 94%;
}
.pay-button {
    border-radius: 24px;
    background: $teritary;
    box-shadow: 0px 24px 40px -12px rgba(0, 0, 0, 0.08);
    margin-top: 1rem;
    margin-bottom: 0px;
    width: 100%;
    max-width: 100%;
    border:none;
    .p-button-label{
        font-size: 15px;
        font-weight: 600;
    }
    &:hover{
        background: $secondary;
    }
    &.continue-button{
        background: $btnprimaryover;
        &:hover{
            background: $btnsecondary;
        }
    }
}